@media screen and (max-width: 1200px) {
  .intro {
    min-height: 200px;
    width: 80%;
    font-size: 60%;
    line-height: 400%;
  }

  .media-link {
    width: 100%;
    margin: 10px 0;
  }

  nav {
    padding-top: 0;
    padding-bottom: 0;
  }
}
