html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Gantari", sans-serif;
  font-weight: 300;
  line-height: 600%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
  min-height: 100vh;
}

h1,
h2 {
  font-weight: 300;
  margin: 20px 0;
}

h2 {
  font-size: 2em;
}

h1 {
  font-size: 3.5em;
}

h4 {
  font-size: 16px;
  font-weight: normal;
  margin: 0 10px;
}
