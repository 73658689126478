@font-face {
  font-family: "Lovelo";
  src: url("./../public/fonts/Lovelo-LineLight.woff") format("woff");
}

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  background-image: url("./../public/DSCF3002.JPG");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.intro {
  min-height: 400px;
  width: 60%;
}

.logo {
  font-family: "Lovelo";
  font-size: 3em;
  color: #fff;
}

.media {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.media-link {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  text-decoration: none;
  text-align: center;
  width: 12%;
  padding: 0 10px;
  margin: 10px;
  border-radius: 4px;
}

.media-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.image-link {
  display: inline-block;
  width: 300px;
  height: 300px;
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
  opacity: 0.9;
  margin: 10px;
}

.image-link img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.image-link:hover {
  opacity: 1;
}

.header,
.intro,
.media,
.footer {
  padding: 0 40px;
}

nav {
  padding-top: 20px;
  padding-bottom: 20px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
}

nav li {
  float: left;
  margin-right: 40px;
}

nav a {
  color: #ccc;
  text-decoration: none;
}

nav a:hover {
  color: #fff;
}

.media iframe {
  margin: 10px;
}
